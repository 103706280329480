import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'
import { getSupportedCA, createSession, sendSessionOption, sendKeyContainer, getCertificateInBase64, getKCInfo,
         getCertificateInfo, getCertificateInfoKeyAgreement, deleteSession, sendTokenPath, getConnecteds, openFileDialog, depositsignLogin, depositsignGetCertInfo,
         vtcoLogin, getVtcoTokenList, getVtcoProtectedParams, getVtcoTokenEnquire } from '../actions/api'

import { setCAId, setCertificateInfo, clearSelectedKeyContainer, setCertificateInfoKeyAgreement, startTic, setCertificateInfoKeyAgreementBase64,
          setSelectedKeyContainer, setKeyContainer, clearKeyContainer, clearCertificateInfoKeyAgreement, setIsActive,
          setKeyContainerPass, clearKeyContainerPass, clearCertificateInfo, setActiveToken, setPassiveToken, setCleanUpAll,
          setSelectedTab, setActivationPeriod, setSelectedMode, setPassiveTokenPath, setActiveTokenPath, clearInputFileForEncrypt,
          clearCertificateInputFile, clearInputFileForDecrypt, clearDecryptedData, clearEncryptedData, clearInputFileForDS,
          clearDSData, clearInputDescription, setKeyContainerPath, clearInputTextForDS,
          clearDSInputTextDescription, clearDSDataBase64, clearFileList, clearFileListWithDS, clearPrevisionDsText,
          clearDecryptText, clearInputTextForDecryptUintArr, clearInputTextForDecrypt, clearInputTextForEncryptUintArr,
          clearInputTextForEncrypt, clearEncryptDataBase64, clearFilesForEncrypt, clearEncryptedFiles,
          clearPrevisionDsFile, setDSActive, setActionsExpand, setMobileDSMode, clearFullPhoneNumber, clearPhoneNumber, clearOwnerOrganization, setShowUpgradeCard,
          clearBaseFileForUpgrade, clearFileWithDSForUpgrade, clearModifiedDSData, clearBaseTextForUpgrade, clearUpgradedDSInBase64, clearTextDSDataBase64,
          clearInputTextDSForVerify, clearVerifyInputText, clearVerifyResultText, clearTextSignedData,
          clearVerifyResultData, clearFileSignedData, clearVerifyInputFile, clearVerifyDSInputFile, clearQRList,
          clearVerifyResultsForAddToExist, clearQRVerifyResultsForAddToExist, setBodyIsMounted, setTokenPathHSM, clearTokenPathHSM,
          setDepositSignUserName, clearDepositSignUserName, setDepositSignKeysInfo, clearDepositSignKeysInfo, setDepositSignSelectedKey, clearDepositSignSelectedKey,
          setUseUccConnection, setUseTwoFactorCode, setTwoFactorCode, setShowPinInput, clearVPNToken, clearVPNTokenData, clearProfileFields, clearResultKey, clearSecret,
          clearErrorRespons, setAllFieldsFiled, setVtcoUserName, clearVtcoUserName, setVtcoKeysInfo, clearVtcoKeysInfo, setVtcoSelectedKey, clearVtcoSelectedKey,
          setTwoFactorEnable, setVtcoTokenBody, setProtectedParams, clearProtectedParams, setIsVtco, setVtcoPin, setVtcoUrl,
          setKeyAgreementCertificate, setSignatureCertificate, setDuplicateSign, setAddDsToExist, setTokenInfo, setShowPreviewPopup,
          setShowPreviewPopupVerify, setShowErrorPopup, setErrorData, setIsKerberos, setSelectedAuthProvider, setAuthProvidersList, setXadesType,
          setAsicContainerForm, setExpireTime, showEncryptPreviewPopup, showDecryptPreviewPopup, setDecryptionError } from '../actions/localStates'

import { cipher } from '../actions/stringEncodersFuncs'

import Cookies from 'universal-cookie';

import CertificateInfoSignature from '../components/body/CertificateInfoSignature'
import CertificateInfoKeyAgreement from '../components/body/CertificateInfoKeyAgreement'
import CertificateInfo from '../components/body/CertificateInfo'
import Popup from '../components/body/Popup'
import MobileDSContainer from './MobileDSContainer'
import JavaDownloadPopup from '../components/body/JavaDownloadPopup'

import winLogo from '../img/windows-11-logo_print.png'
import linuxLogo from '../img/linux_logo_icon_171222.png'
import appleLogo from '../img/apple_logo_icon_168588.png'

const cookies = new Cookies();

class PrivateKeyContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
          listCSK: this.props.availablesCSK,
          keysProfiles: [],
          uuid: null,
          isLoading: false,
          defaultKeyValues:[
              "[файл на диску]",
              "PKCS#11 Active",
              "PKCS#11 Passive"
          ],
          selectedMode: this.props.pkLocalState.selectedMode,
          showPopup: false,
          showJavaDownloadPopup: false,
          showPassword: false,
          selectedKeyContainer: this.props.pkLocalState.selectedKeyContainer,
          collapseActionsShow: "",
          showVtcoPin: false,
          pinEnabled: true,
          showTokenList: false,
          selectedToken: 0
      }

      this.onDropdownSelected = this.onDropdownSelected.bind(this);
      this.onDropdownModeSelected = this.onDropdownModeSelected.bind(this);
      this.handleKCFile = this.handleKCFile.bind(this);
      this.onChangeFile = this.onChangeFile.bind(this);
      this.onChangeKCFile = this.onChangeKCFile.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
      this.handlePwdKeyUp = this.keyUpHandler.bind(this, 'PwdInput');
      this.onDropdownActiveTokenPathSelected = this.onDropdownActiveTokenPathSelected.bind(this);
      this.onDropdownPassiveTokenPathSelected = this.onDropdownPassiveTokenPathSelected.bind(this);
      this.withKC = this.withKC.bind(this);
      this.withToken = this.withToken.bind(this);
      this.clearAll = this.clearAll.bind(this)
      this.toggleJavaDownloadPopup = this.toggleJavaDownloadPopup.bind(this);

      this.handleTokenPathChange = this.handleTokenPathChange.bind(this);
      this.handleTokenUserNameChange = this.handleTokenUserNameChange.bind(this);
      this.handleTwoFactorCodeChange = this.handleTwoFactorCodeChange.bind(this);

      this.handleVtcoUserNameChange = this.handleVtcoUserNameChange.bind(this);

      this.onDepositsignKeyChanged = this.onDepositsignKeyChanged.bind(this);
      this.onVtcoKeyChanged = this.onVtcoKeyChanged.bind(this);

      this.enterKeyUpHandler = this.enterKeyUpHandler.bind(this, 'enter');

      this.getVtcoTokenEnquire = this.getVtcoTokenEnquire.bind(this);
      this.handleVtcoPinChange = this.handleVtcoPinChange.bind(this);

      this.onProviderChanged = this.onProviderChanged.bind(this);

      this.setShowTokenList = this.setShowTokenList.bind(this);
  }

  buildKCFileSelector () {
    const KCfile = document.getElementById('keyContainerUpload');
    return KCfile;
  }

  setShowTokenList () {
    this.setState({showTokenList: !this.state.showTokenList})
  }

  expandActions () {
    this.props.actions.setActionsExpand(this.props.expandedCardsReducer.actionsExpanded)
    this.props.expandedCardsReducer.actionsExpanded ? this.setState({collapseActionsShow: "card-body collapse"}) : this.setState({collapseActionsShow: "card-body collapse show"})
  }

  componentDidMount () {
    document.addEventListener('keyup', this.enterKeyUpHandler);
    var caasActivationPeriod = cookies.get('caasActivationPeriod');
    if (caasActivationPeriod !== null && caasActivationPeriod !== undefined) {
      this.props.actions.setActivationPeriod(caasActivationPeriod)
    }
    
    var _this = this;
      this.KCfile = this.buildKCFileSelector();
      this.props.actions.clearSelectedKeyContainer(this.props.base.defaultACSKId)
      this.props.expandedCardsReducer.actionsExpanded ? this.setState({collapseActionsShow: "card-body collapse show"}) : this.setState({collapseActionsShow: "card-body collapse"})
      
      this.props.actions.setBodyIsMounted(true);
      
  }

  togglePopup () {
    document.activeElement.blur()
    this.props.actions.getConnecteds(this.props.connectionStatus.baseUrl)
      .then((response) => {
        console.log(response);
        if (response.passiveTokenPath.length > 0) {
          this.props.actions.setPassiveTokenPath(response.passiveTokenPath)
        }
        if (response.activeTokenPath.length > 0) {
          this.props.actions.setActiveTokenPath(response.activeTokenPath)
        }
    })
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  toggleJavaDownloadPopup () {
    if (this.state.showJavaDownloadPopup) {
        if (!this.props.connectionStatus.uccConnection) {
        this.props.actions.setSelectedMode(0)

      }

      let element = document.getElementById("kt-input");
      element.value = 0;
    }

    document.activeElement.blur()
    this.setState({
      showJavaDownloadPopup: !this.state.showJavaDownloadPopup
    });
  }

  componentDidUpdate() {
    if (this.props.pkLocalState.keyContainer === null) {
        this.KCfile = this.buildKCFileSelector();
    }
    if (this.props.pkLocalState.keyContainer !== null && !this.props.pkLocalState.isActive) {
        this.KCfile = this.buildKCFileSelector();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.enterKeyUpHandler);
  }

  onChangeKCFile(e) {
      this.props.actions.setKeyContainer(e.target.files[0])
  }

  handleKCFile(e) {
      e.preventDefault();
      if (this.props.connectionStatus.uccConnection) {
          this.props.actions.openFileDialog(this.props.connectionStatus.baseUrl)
          .then((response) => {
            console.log(response);
            this.props.actions.setKeyContainerPath(response.filePath)
          })
      } else {
        this.KCfile.value = null;
        this.KCfile.click()
      }
      document.activeElement.blur()
  }

  formatDate(dateInString) {
      var localeDateOptions = {
          "day": "numeric",
          "month": "numeric",
          "year": "numeric",
          "hour": "2-digit",
          "minute": "2-digit",
          "second": "2-digit",
          "timeZoneName": "short"
      }

      return new Date(dateInString).toLocaleDateString(i18next.language, localeDateOptions);
  }

  setShowPassword() {
    this.setState({showPassword: this.state.showPassword ? false : true})
  }

  hover() {
    this.setState({showPassword: true})
  }

  out() {
    this.setState({showPassword: false})
  }

  setShowVtcoPin() {
    this.setState({showVtcoPin: this.state.showVtcoPin ? false : true})
  }

  hoverPin() {
    this.setState({showVtcoPin: true})
  }

  outPin() {
    this.setState({showVtcoPin: false})
  }

  onChangeFile(e) {
    if (e.target.files[0] !== undefined) {
      this.props.actions.setKeyContainer(e.target.files[0])
    }
  }

  onChangeDigits(e){
    var period = 15;
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value >= 720) {
        period = 720
      } else {
        period = e.target.value
      }
      this.props.actions.setActivationPeriod(period)
    }
    
  }

  enterKeyUpHandler(refName, e) {
    if (e.keyCode === 13 && e.target.tagName === 'BODY') {
      document.getElementById("submit").click();
      document.activeElement.blur();
    }
  }

  keyUpHandler(refName, e) {
      if (e.keyCode === 13) {
        document.getElementById("submit").click();
        document.activeElement.blur()
      }
  }

  handlePasswordChange(e) {
    this.props.actions.setKeyContainerPass(e.target.value)
  }

  handleTokenPathChange(e) {
    this.props.actions.setTokenPathHSM(e.target.value)
  }

  handleTokenUserNameChange(e) {
    this.props.actions.setDepositSignUserName(e.target.value)
  }

  handleVtcoUserNameChange(e) {
    this.props.actions.setVtcoUserName(e.target.value)
  }

  handleTwoFactorCodeChange (e) {
    this.props.actions.setTwoFactorCode(e.target.value)
    if (e.target.value.length > 0) {
      this.props.actions.setUseTwoFactorCode(true)
    } else {
      this.props.actions.setUseTwoFactorCode(false)
    }
  }

  clearAll() {
    this.props.actions.clearInputFileForEncrypt()
    this.props.actions.clearCertificateInputFile()
    this.props.actions.clearInputFileForDecrypt()
    this.props.actions.clearDecryptedData()
    this.props.actions.clearEncryptedData()
    this.props.actions.clearInputFileForDS()
    this.props.actions.clearDSData()
    this.props.actions.clearInputDescription()
    this.props.actions.clearInputTextForDS()
    this.props.actions.clearDSInputTextDescription()
    this.props.actions.clearDSDataBase64()
    this.props.actions.clearFileList()
    this.props.actions.clearFileListWithDS()
    this.props.actions.clearPrevisionDsText()
    this.props.actions.clearDecryptText()
    this.props.actions.clearInputTextForDecryptUintArr()
    this.props.actions.clearInputTextForDecrypt()
    this.props.actions.clearInputTextForEncryptUintArr()
    this.props.actions.clearInputTextForEncrypt()
    this.props.actions.clearEncryptDataBase64()
    this.props.actions.clearPrevisionDsFile()
    this.props.actions.clearInputDescription()
    this.props.actions.clearFilesForEncrypt()
    this.props.actions.clearEncryptedFiles()
    this.props.actions.clearFullPhoneNumber()
    this.props.actions.clearPhoneNumber()
    this.props.actions.clearOwnerOrganization()
    this.props.actions.clearBaseFileForUpgrade()
    this.props.actions.clearFileWithDSForUpgrade()
    this.props.actions.clearModifiedDSData()
    this.props.actions.clearUpgradedDSInBase64()
    this.props.actions.clearTextDSDataBase64()
    this.props.actions.clearBaseTextForUpgrade()
    this.props.actions.clearInputTextDSForVerify()
    this.props.actions.clearVerifyInputText()
    this.props.actions.clearVerifyResultText()
    this.props.actions.clearTextSignedData()
    this.props.actions.clearVerifyResultData()
    this.props.actions.clearFileSignedData()
    this.props.actions.clearVerifyInputFile()
    this.props.actions.clearVerifyDSInputFile()
    this.props.actions.clearQRList()
    this.props.actions.clearVerifyResultsForAddToExist()
    this.props.actions.clearQRVerifyResultsForAddToExist()
    this.props.actions.clearDepositSignKeysInfo()
    this.props.actions.clearDepositSignSelectedKey()
    this.props.actions.setUseUccConnection(false)
    this.props.actions.setUseTwoFactorCode(false)
    this.props.actions.setShowPinInput(false)
    this.props.actions.setTwoFactorCode("")
    this.props.actions.clearVPNToken()
    this.props.actions.clearVPNTokenData()
    this.props.actions.clearProfileFields()
    this.props.actions.clearResultKey()
    this.props.actions.clearSecret()
    this.props.actions.clearErrorRespons()
    this.props.actions.setAllFieldsFiled(true)
    this.props.actions.clearVtcoKeysInfo()
    this.props.actions.clearVtcoSelectedKey()
    this.props.actions.setTwoFactorEnable(false)
    this.props.actions.setVtcoTokenBody("")
    this.props.actions.clearProtectedParams()
    this.props.actions.setIsVtco(false)
    this.props.actions.setVtcoPin("")
    this.props.actions.setKeyAgreementCertificate(null)
    this.props.actions.setSignatureCertificate(null)
    this.props.actions.setDuplicateSign(false)
    this.props.actions.setAddDsToExist("notSignedBefore")
    this.props.actions.setTokenInfo(null)
    this.props.actions.setShowPreviewPopup(false)
    this.props.actions.setShowPreviewPopupVerify(false);
    this.props.actions.setIsKerberos(false)
    this.props.actions.setAuthProvidersList(null)
    this.props.actions.setXadesType("NONE")
    this.props.actions.setAsicContainerForm("NONE")
    this.props.actions.showEncryptPreviewPopup(false)
    this.props.actions.showDecryptPreviewPopup(false)
    this.props.actions.setDecryptionError(false)
  }

  cleanUpForm() {
    this.props.actions.clearKeyContainer()
    this.props.actions.setCleanUpAll(true)
    this.props.actions.setMobileDSMode(false)
    this.props.actions.setActivationPeriod(this.props.pkLocalState.settedTime)
    this.props.actions.setShowUpgradeCard(false)
    this.props.actions.clearSelectedKeyContainer(this.props.base.defaultACSKId)
    this.props.actions.clearKeyContainerPass()
    this.props.actions.clearCertificateInfo()
    this.props.actions.clearCertificateInfoKeyAgreement()
    this.props.actions.clearTokenPathHSM()
    this.props.actions.clearDepositSignUserName()
    this.props.actions.clearVtcoUserName()
    this.props.actions.clearDepositSignKeysInfo()
    this.props.actions.clearDepositSignSelectedKey()
    this.props.actions.setUseUccConnection(false)
    this.props.actions.setUseTwoFactorCode(false)
    this.props.actions.setShowPinInput(false)
    this.props.actions.setIsActive(false)
    this.props.actions.setSelectedMode("0")
    this.clearAll()
  }

  confirm() {
      this.props.actions.setCAId(this.props.pkLocalState.selectedKeyContainer)
      this.getCertInfo()
  }

  endWork() {
      this.cleanUpForm()
  }

  setSelectedCertificateInfoTab() {
    this.props.actions.setSelectedTab("CertificateInfo")
  }

  setSelectedSignatureTab() {
    this.props.actions.setSelectedTab("CertificateInfoSignature")
  }

  setSelectedKeyAgreementTab() {
    this.props.actions.setSelectedTab("CertificateInfoKeyAgreement")
  }

  withKC(caId) {
    var url, errorMessage;

    if ((this.props.connectionStatus.uccConnection === true && this.props.pkLocalState.selectedMode === "0") || (this.props.connectionStatus.uccConnection === true && this.props.pkLocalState.selectedMode === "1") || (this.props.connectionStatus.uccConnection === true && this.props.pkLocalState.selectedMode === "2")) {
      this.props.actions.setUseUccConnection(true)
      url = this.props.base.urlUCC + "/api/v1"
    } else {
      this.props.actions.setUseUccConnection(false)
      url = this.props.base.urlCloud + "/api/v1"
    }

    this.setState({isLoading: true})
    return this.props.actions.createSession(url)
        .then((response) => {
          this.setState({uuid: response.ticketUuid})
          console.log(response);
        })
          .then(() => this.props.actions.sendSessionOption(url, this.state.uuid, caId))
          .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer))
          .then(() => this.props.actions.getKCInfo(url, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
          .then((response) => {
                if (response !== null && Object.keys(response).length > 0) {
                    if (response.message !== undefined && response.message.length > 0) {
                      errorMessage = {
                        message: response.message,
                        techMessage: response.failureCause
                      }
                      this.props.actions.setErrorData(errorMessage)
                      this.props.actions.setShowErrorPopup(true)
                      // alert(response.message + '\n' + response.failureCause);
                    } else {
                      if (response.keyAgreement.canBeUsed && Object.keys(response.keyAgreement.certificateInfo).length > 0) {
                          this.props.actions.setCertificateInfoKeyAgreement(response.keyAgreement.certificateInfo)
                          console.log(response.keyAgreement);
                      }

                      if (response.signature.canBeUsed && Object.keys(response.signature.certificateInfo).length > 0) {
                          this.props.actions.setCertificateInfo(response.signature.certificateInfo)
                          console.log(response.signature);
                      }

                      if (response.keyAgreement.message.length > 0 && response.signature.message.length > 0) {
                          errorMessage = {
                            certWarning: true,
                            signatureMessage: response.signature.message,
                            agreementMessage: response.keyAgreement.message
                          }
                          this.props.actions.setErrorData(errorMessage)
                          this.props.actions.setShowErrorPopup(true)
                          // alert(i18next.t("pk:keyAgreementTitle") + response.keyAgreement.message + "\n" + i18next.t("pk:signatureTitle") + response.signature.message);
                      } else if (response.keyAgreement.message.length > 0) {
                          errorMessage = {
                            certWarning: true,
                            agreementMessage: response.keyAgreement.message
                          }
                          this.props.actions.setErrorData(errorMessage)
                          this.props.actions.setShowErrorPopup(true)
                          // alert(i18next.t("pk:keyAgreementTitle") + response.keyAgreement.message)
                      } else if (response.signature.message.length > 0) {
                          errorMessage = {
                            certWarning: true,
                            signatureMessage: response.signature.message
                          }
                          this.props.actions.setErrorData(errorMessage)
                          this.props.actions.setShowErrorPopup(true)
                          // alert(i18next.t("pk:signatureTitle") + response.signature.message)
                      }

                      if ((!response.keyAgreement.canBeUsed && response.keyAgreement.message.length === 0) && (!response.signature.canBeUsed && response.signature.message.length === 0)) {
                          errorMessage = {
                            message: i18next.t("pk:unnableToCreateContextTitle")
                          }
                          this.props.actions.setErrorData(errorMessage)
                          this.props.actions.setShowErrorPopup(true)
                          // alert(i18next.t("pk:unnableToCreateContextTitle"));
                      }
                    }
                } else {
                  console.log(response);
                }
          }).then(() => {
              if (this.props.certificateInfo || this.props.certificateInfoKeyAgreement) {
                  this.setState({isLoading: false})
                  this.props.actions.startTic(this.props.pkLocalState.settedTime)
                  this.props.actions.setDSActive(true)
                  this.props.actions.setIsActive(true)
                  this.props.actions.setCleanUpAll(false)
              } else {
                  this.setState({isLoading: false})
              }
            }).catch((err) => {
                this.setState({isLoading: false})
                alert(err);
            })
          .then(() => this.props.actions.deleteSession(url, this.state.uuid))
  }

  withToken(caId, settedToken) {
      var url, errorMessage;

      if ((this.props.connectionStatus.uccConnection === true && this.props.pkLocalState.selectedMode === "0") || (this.props.connectionStatus.uccConnection === true && this.props.pkLocalState.selectedMode === "1") || (this.props.connectionStatus.uccConnection === true && this.props.pkLocalState.selectedMode === "2")) {
        this.props.actions.setUseUccConnection(true)
        url = this.props.base.urlUCC + "/api/v1"
      } else {
        this.props.actions.setUseUccConnection(false)
        url = this.props.base.urlCloud + "/api/v1"
      }

      this.setState({isLoading: true})
      var keyContainerPassword
      if (this.props.pkLocalState.depositsignSelectedKey !== null) {
        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(publicKey);

        if (this.props.pkLocalState.useTwoFactorCode) {
            keyContainerPassword = aes.encrypt(this.props.pkLocalState.keyContainerPassword, this.props.pkLocalState.twoFactorCode).toString();
        } else {
            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.keyContainerPassword);
        }
      } else {
        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
      }


      return this.props.actions.createSession(url)
          .then((response) => {
            this.setState({uuid: response.ticketUuid})
            console.log(response);
          })
            .then(() => this.props.actions.sendSessionOption(url, this.state.uuid, caId))
            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null))
            .then(() => this.props.actions.getKCInfo(url, this.state.uuid, keyContainerPassword))
            .then((response) => {
                  if (response !== null && Object.keys(response).length > 0) {
                      if (response.message !== undefined && response.message.length > 0) {
                        errorMessage = {
                          message: response.message,
                          techMessage: response.failureCause
                        }
                        this.props.actions.setErrorData(errorMessage)
                        this.props.actions.setShowErrorPopup(true)
                        // alert(response.message + '\n' + response.failureCause);
                      } else {
                        if (response.keyAgreement.canBeUsed && Object.keys(response.keyAgreement.certificateInfo).length > 0) {
                            this.props.actions.setCertificateInfoKeyAgreement(response.keyAgreement.certificateInfo)
                            console.log(response.keyAgreement);
                        }

                        if (response.signature.canBeUsed && Object.keys(response.signature.certificateInfo).length > 0) {
                            this.props.actions.setCertificateInfo(response.signature.certificateInfo)
                            console.log(response.signature);
                        }

                        if (response.keyAgreement.message.length > 0 && response.signature.message.length > 0) {
                            errorMessage = {
                              certWarning: true,
                              signatureMessage: response.signature.message,
                              agreementMessage: response.keyAgreement.message
                            }
                            this.props.actions.setErrorData(errorMessage)
                            this.props.actions.setShowErrorPopup(true)
                            // alert(i18next.t("pk:keyAgreementTitle") + response.keyAgreement.message + "\n" + i18next.t("pk:signatureTitle") + response.signature.message);
                        } else if (response.keyAgreement.message.length > 0) {
                            errorMessage = {
                              certWarning: true,
                              agreementMessage: response.keyAgreement.message
                            }
                            this.props.actions.setErrorData(errorMessage)
                            this.props.actions.setShowErrorPopup(true)
                            // alert(i18next.t("pk:keyAgreementTitle") + response.keyAgreement.message)
                        } else if (response.signature.message.length > 0) {
                            errorMessage = {
                              certWarning: true,
                              signatureMessage: response.signature.message
                            }
                            this.props.actions.setErrorData(errorMessage)
                            this.props.actions.setShowErrorPopup(true)
                            // alert(i18next.t("pk:signatureTitle") + response.signature.message)
                        }

                        if ((!response.keyAgreement.canBeUsed && response.keyAgreement.message.length === 0) && (!response.signature.canBeUsed && response.signature.message.length === 0)) {
                            errorMessage = {
                              message: i18next.t("pk:unnableToCreateContextTitle")
                            }
                            this.props.actions.setErrorData(errorMessage)
                            this.props.actions.setShowErrorPopup(true)
                            // alert(i18next.t("pk:unnableToCreateContextTitle"));
                        }
                      }
                  } else {
                    console.log(response);
                  }
            }).then(() => {
                if (this.props.certificateInfo || this.props.certificateInfoKeyAgreement) {
                    // alert(i18next.t("pk:pkResponseSuccessTitle"));
                    this.setState({isLoading: false})
                    this.props.actions.startTic(this.props.pkLocalState.settedTime)
                    this.props.actions.setDSActive(true)
                    this.props.actions.setIsActive(true)
                    this.props.actions.setCleanUpAll(false)
                } else {
                    // alert(i18next.t("pk:pkResponseFailTitle"));
                    this.setState({isLoading: false})
                }
              }).catch((err) => {
                  this.setState({isLoading: false})
                  alert(err);
              })
            .then(() => this.props.actions.deleteSession(url, this.state.uuid))
      }

  getCertInfo () {
      let caId = {
        caId: this.props.pkLocalState.selectedKeyContainer
      }

      let settedToken = {
        keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
      }

      if (this.props.pkLocalState.vtcoSelectedKey !== null) {
        this.getVtcoTokenEnquire();
        return;
      }

      if (this.props.pkLocalState.keyContainer !== null) {
          if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
              let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
              this.withToken(caId, keyPath)
          } else {
              this.withKC(caId)
          }
      } else {
          if (this.props.pkLocalState.tokenPathHSM.length > 0) {
              let keyPath = {"keyStoreUri": this.props.pkLocalState.tokenPathHSM}
              this.withToken(caId, keyPath)
          } else {
              if (this.props.pkLocalState.depositsignSelectedKey !== null) {
                  let keyPath = {"keyStoreUri": this.props.pkLocalState.depositsignSelectedKey.KeyName}
                  this.withToken(caId, keyPath)
              } else {
                  this.withToken(caId, settedToken)
              }
          }
      }
  }

  getVtcoTokenEnquire () {
    var _this = this, errorMessage;
    var jsonObject = {
        pin: this.props.pkLocalState.vtcoPin
    };

    var cipherResult = cipher(jsonObject, this.props.pkLocalState.publicKeyTextual, this.props.pkLocalState.currentUtcDateTime);

    var data = {
        "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
        "encryptedData": cipherResult.encryptedData,
        "secretKey": cipherResult.secretKey
    }

    this.props.actions.getVtcoTokenEnquire(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
    .then((response) => {
        if (response.code !== undefined) {
            this.setState({isLoading: false})
            console.log(response);
            errorMessage = {
              message: response.message,
              techMessage: response.techMessage
            }
            this.props.actions.setErrorData(errorMessage)
            this.props.actions.setShowErrorPopup(true)
            // alert(response.message + '\n' + response.techMessage)
        } else {
            if (response.agreementKey === null && response.signatureKey === null) {
                _this.setState({"pinEnabled": false})
                errorMessage = {
                  message: i18next.t("noKeysWarning")
                }
                _this.props.actions.setErrorData(errorMessage)
                _this.props.actions.setShowErrorPopup(true)
                // alert(i18next.t("noKeysWarning"))
                return;
            } else {
                if (response.agreementKey !== null && Object.keys(response.agreementKey.certificateInfo).length > 0) {
                    this.props.actions.setCertificateInfoKeyAgreement(response.agreementKey.certificateInfo)
                    this.props.actions.setKeyAgreementCertificate(response.agreementKey.certificate)
                    console.log(response.keyAgreement);
                }

                if (response.signatureKey !== null && Object.keys(response.signatureKey.certificateInfo).length > 0) {
                    this.props.actions.setCertificateInfo(response.signatureKey.certificateInfo)
                    this.props.actions.setSignatureCertificate(response.signatureKey.certificate)
                    console.log(response.signature);
                }

                function parseJwt (token) {
                    var base64Url = token.split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));

                    return JSON.parse(jsonPayload);
                };

                var userData = parseJwt(this.props.pkLocalState.vtcoTokenBody);

                this.props.actions.setExpireTime(userData.exp)

                function calculateMinutesDifference(utcTimestamp) {
                    const now = Date.now();

                    const targetTimeInMs = utcTimestamp * 1000;

                    const differenceInMs = targetTimeInMs - now;

                    const differenceInMinutes = Math.floor(differenceInMs / 1000 / 60);

                    return differenceInMinutes;
                }
                const minutesDifference = calculateMinutesDifference(userData.exp);
                console.log(`Різниця в хвилинах: ${minutesDifference + 1}`);

                this.props.actions.setActivationPeriod(minutesDifference + 1)
            }
        }
    })
    .then(() => {
      if (this.props.certificateInfo || this.props.certificateInfoKeyAgreement) {
          this.setState({isLoading: false});
          this.setState({"pinEnabled": true})
          this.props.actions.setIsVtco(true);
          this.props.actions.startTic(this.props.pkLocalState.settedTime);
          this.props.actions.setDSActive(true);
          this.props.actions.setIsActive(true);
          this.props.actions.setCleanUpAll(false);
      } else {
          this.setState({isLoading: false});
      }
    })
  }

  getDepositsignCertInfo () {
      this.props.actions.depositsignGetCertInfo(this.props.pkLocalState.depositsignSelectedKey.KeyName, this.props.pkLocalState.depositsignKeysInfo.Token)
      .then((response) => {
        console.log(response);
      })
  }

  onDropdownSelected (e) {
      this.props.actions.setSelectedKeyContainer(e.target.value)
  }

  getKeys () {
      this.setState({isLoading: true})
      var keyContainerPassword, errorMessage;

      const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

      const jsEncrypt = new JSEncrypt();
      jsEncrypt.setPublicKey(publicKey);

      if (this.props.pkLocalState.useTwoFactorCode) {
          keyContainerPassword = aes.encrypt(this.props.pkLocalState.keyContainerPassword, this.props.pkLocalState.twoFactorCode).toString();
      } else {
          keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.keyContainerPassword);
      }

      this.props.actions.depositsignLogin(this.props.pkLocalState.depositsignUserName, keyContainerPassword)
      .then((response) => {
            if (response.Code !== undefined) {
                this.setState({isLoading: false})
                console.log(response);
                errorMessage = {
                  message: response.Message
                }
                this.props.actions.setErrorData(errorMessage)
                this.props.actions.setShowErrorPopup(true)
                // alert(response.Message)
            } else {
                this.props.actions.setDepositSignSelectedKey(response.KeysInfo[0])
                this.props.actions.setDepositSignKeysInfo(response);
                this.setState({isLoading: false})
                console.log(response);
            }
          })
  }

  getVtcoKeys () {
    var errorMessage, _this = this, data, sortedArray = [];;
      this.props.actions.setUseUccConnection(false)

      this.setState({isLoading: true})

      if (this.props.base.isKerberos) {
          let xhr = new XMLHttpRequest();
            xhr.open('GET', this.props.base.selectedAuthProvider);
            xhr.withCredentials = true;

            xhr.send();

            xhr.onerror = function() {
                alert(i18next.t("kerberosError"))
            }

            xhr.onload = function() {
                if (xhr.status !== 200) {
                    _this.setState({isLoading: false})
                    errorMessage = {
                      message: i18next.t("kerberosError")
                    }
                    _this.props.actions.setErrorData(errorMessage)
                    _this.props.actions.setShowErrorPopup(true)
                    return;
                }

                let response = JSON.parse(xhr.response);
                console.log(response);

                _this.props.actions.setVtcoTokenBody(response["access_token"])

                _this.props.actions.getVtcoTokenList(_this.props.base.vtcoURL, response["access_token"])
                .then((response) => {
                  if (response.code !== undefined) {
                      _this.setState({"pinEnabled": true})
                      _this.setState({isLoading: false})
                      console.log(response);
                      errorMessage = {
                        message: response.message,
                        techMessage: response.techMessage
                      }
                      _this.props.actions.setErrorData(errorMessage)
                      _this.props.actions.setShowErrorPopup(true)
                      // alert(response.message + '\n' + response.techMessage)
                  } else {
                      response.map((key) => {
                        if ((key.agreementKey !== null && key.agreementKey !== undefined && key.agreementKey.isElectronicStamp === false) || (key.signatureKey !== null && key.signatureKey !== undefined && key.signatureKey.isElectronicStamp === false)) {
                          sortedArray.push(key)
                        }
                      })

                      response.map((key) => {
                        if ((key.agreementKey !== null && key.agreementKey !== undefined && key.agreementKey.isElectronicStamp === true) || (key.signatureKey !== null && key.signatureKey !== undefined && key.signatureKey.isElectronicStamp === true)) {
                          sortedArray.push(key)
                        }
                      })

                      _this.props.actions.setVtcoSelectedKey(sortedArray[0])
                      _this.props.actions.setVtcoKeysInfo(sortedArray)

                      _this.props.actions.getVtcoProtectedParams(_this.props.base.vtcoURL, _this.props.pkLocalState.vtcoTokenBody)
                      .then((response) => {
                        if (response.code !== undefined) {
                            _this.setState({"pinEnabled": true})
                            _this.setState({isLoading: false})
                            console.log(response);
                            errorMessage = {
                              message: response.message,
                              techMessage: response.techMessage
                            }
                            _this.props.actions.setErrorData(errorMessage)
                            _this.props.actions.setShowErrorPopup(true)
                            // alert(response.message + '\n' + response.techMessage)
                        } else {
                            _this.props.actions.setProtectedParams(response)
                            _this.setState({"pinEnabled": true})
                            _this.setState({isLoading: false})
                            console.log(response);
                        }
                      })
                  }
                })
            }
      } else {
          var providerType, token;

          this.props.base.authProvidersList.map((provider, index) => {
            if (provider.url === this.props.base.selectedAuthProvider) {
              providerType = provider.type;
            }
          })

          switch (providerType) {
            case "cipher-auth":
              data = {
                userName : this.props.pkLocalState.vtcoUserName,
                password : this.props.pkLocalState.keyContainerPassword,
                clientId : this.props.base.clientId
              }
              break;

            case "ad":
              data = {
                user : this.props.pkLocalState.vtcoUserName,
                password : this.props.pkLocalState.keyContainerPassword
              }
              break;

            default:
              data = {
                userName : this.props.pkLocalState.vtcoUserName,
                password : this.props.pkLocalState.keyContainerPassword,
                clientId : this.props.base.clientId
              }
              break;
          }         

          this.props.actions.vtcoLogin(this.props.base.selectedAuthProvider, data)
          .then((response) => {
            if (response.code !== undefined) {
                this.setState({isLoading: false})
                console.log(response);
                errorMessage = {
                  message: response.message,
                  techMessage: response.techMessage
                }
                this.props.actions.setErrorData(errorMessage)
                this.props.actions.setShowErrorPopup(true)
                // alert(response.message + '\n' + response.techMessage)
            } else {
                if (response.status !== undefined && response.status !== 200) {
                  this.setState({isLoading: false})
                  console.log(response);
                  errorMessage = {
                    message: response.error
                  }
                  this.props.actions.setErrorData(errorMessage)
                  this.props.actions.setShowErrorPopup(true)
                  return;
                }

                this.props.actions.setTwoFactorEnable(response.isTwoFactorEnable)
                if (response.access_token !== undefined) {
                    token = response.access_token
                } else {
                    token = response.tokenBody
                }

                this.props.actions.setVtcoTokenBody(token)

                this.props.actions.getVtcoTokenList(this.props.base.vtcoURL, token)
                .then((response) => {
                  if (response.code !== undefined) {
                      this.setState({"pinEnabled": true})
                      this.setState({isLoading: false})
                      console.log(response);
                      errorMessage = {
                        message: response.message,
                        techMessage: response.techMessage
                      }
                      this.props.actions.setErrorData(errorMessage)
                      this.props.actions.setShowErrorPopup(true)
                      // alert(response.message + '\n' + response.techMessage)
                  } else {
                      response.map((key) => {
                        if ((key.agreementKey !== null && key.agreementKey !== undefined && key.agreementKey.isElectronicStamp === false) || (key.signatureKey !== null && key.signatureKey !== undefined && key.signatureKey.isElectronicStamp === false)) {
                          sortedArray.push(key)
                        }
                      })

                      response.map((key) => {
                        if ((key.agreementKey !== null && key.agreementKey !== undefined && key.agreementKey.isElectronicStamp === true) || (key.signatureKey !== null && key.signatureKey !== undefined && key.signatureKey.isElectronicStamp === true)) {
                          sortedArray.push(key)
                        }
                      })

                      _this.props.actions.setVtcoSelectedKey(sortedArray[0])
                      _this.props.actions.setVtcoKeysInfo(sortedArray)
                      console.log(sortedArray)

                      this.props.actions.getVtcoProtectedParams(this.props.base.vtcoURL, this.props.pkLocalState.vtcoTokenBody)
                      .then((response) => {
                        if (response.code !== undefined) {
                            this.setState({"pinEnabled": true})
                            this.setState({isLoading: false})
                            console.log(response);
                            errorMessage = {
                              message: response.message,
                              techMessage: response.techMessage
                            }
                            this.props.actions.setErrorData(errorMessage)
                            this.props.actions.setShowErrorPopup(true)
                            // alert(response.message + '\n' + response.techMessage)
                        } else {
                            this.props.actions.setProtectedParams(response)
                            this.setState({"pinEnabled": true})
                            this.setState({isLoading: false})
                            console.log(response);
                        }
                      })
                  }
                })
            }
          })
      }
  }

  onDropdownModeSelected(e) {
    var _this = this, num = 0;
    // e.preventDefault();

    this.props.actions.setSelectedMode(e.target.value)

    if ((e.target.value === "0" && this.props.connectionStatus.uccConnection) || (e.target.value === "1" && this.props.connectionStatus.uccConnection) || (e.target.value === "2" && this.props.connectionStatus.uccConnection)) {
      this.props.actions.setUseUccConnection(true)
    } else {
      this.props.actions.setUseUccConnection(false)
    }

    if (e.target.value === "5") {
      this.props.actions.setShowPinInput(true)
    } else {
      this.props.actions.setShowPinInput(false)
    }

    num = parseInt(e.target.value, 10)

    if (num >= 6) {
      var index = num - 6
      this.props.actions.setVtcoUrl(this.props.base.vtcoList[index].url);
      if (this.props.base.vtcoList[index].authProviders !== undefined && this.props.base.vtcoList[index].authProviders.length > 1) {
          this.props.actions.setAuthProvidersList(this.props.base.vtcoList[index].authProviders)
          this.props.actions.setSelectedAuthProvider(this.props.base.vtcoList[index].authProviders[0].url)
        // ["kerberos"] !== undefined && this.props.base.vtcoList[index]["kerberos"] === "true"
        // this.props.actions.setIsKerberos(true)
      } else {
        if (this.props.base.vtcoList[index].authProviders !== undefined && this.props.base.vtcoList[index].authProviders.length > 0) {
          this.props.actions.setSelectedAuthProvider(this.props.base.vtcoList[index].authProviders[0].url + "/token.auth")
          if (this.props.base.vtcoList[index].authProviders.type !== undefined && this.props.base.vtcoList[index].authProviders.type === "kerberos") {
            this.props.actions.setIsKerberos(true)
          }
        } else {
          this.props.actions.setSelectedAuthProvider(this.props.base.vtcoList[index].url + "/token.auth")
          var provider = [{
            "url": this.props.base.vtcoList[index].url + "/token.auth",
            "clientTitle": "Шифр-Auth",
            "type": "cipher-auth"
          }]
          this.props.actions.setAuthProvidersList(provider)
        }
        
        // this.props.actions.setIsKerberos(false)
      }
    } else {
        this.props.actions.setAuthProvidersList(null)
    }

    if (e.target.value === "3") {
      this.props.actions.setMobileDSMode(true)
      this.props.actions.setUseUccConnection(false)
      return
    } else {
      if (!this.props.connectionStatus.uccConnection) {
          if (e.target.value === "1" || e.target.value === "2") {
              this.toggleJavaDownloadPopup();
          }
      }

      this.props.actions.getConnecteds(this.props.connectionStatus.baseUrl)
      .then((response) => {
        console.log(response);
          if (response.passiveTokenPath.length > 0) {
            this.props.actions.setPassiveTokenPath(response.passiveTokenPath)
          } else {
            this.props.actions.setPassiveTokenPath(null)
          }

          if (response.activeTokenPath.length > 0) {
            this.props.actions.setActiveTokenPath(response.activeTokenPath)
          } else {
            this.props.actions.setActiveTokenPath(null)
          }

          if (response.tokenInfo !== null && response.tokenInfo !== undefined && response.tokenInfo.length > 0) {
            this.props.actions.setTokenInfo(response.tokenInfo)
          }
      })
      .then(() => {
        if (_this.props.pkLocalState.selectedMode === "1") {
          if (_this.props.pkLocalState.activeTokenPath !== null && _this.props.pkLocalState.activeTokenPath[0] !== undefined && _this.props.pkLocalState.activeTokenPath.length > 0) {
            _this.props.actions.setPassiveToken("")
            _this.props.actions.setActiveToken(_this.props.pkLocalState.activeTokenPath[0])
          } else {
            _this.props.actions.setPassiveToken("")
            _this.props.actions.setActiveToken(null)
          }
        }

        if (_this.props.pkLocalState.selectedMode === "2") {
          if (_this.props.pkLocalState.passiveTokenPath !== null && _this.props.pkLocalState.passiveTokenPath[0] !== undefined && _this.props.pkLocalState.passiveTokenPath.length > 0) {
            _this.props.actions.setActiveToken("")
            _this.props.actions.setPassiveToken(_this.props.pkLocalState.passiveTokenPath[0])
          } else {
            _this.props.actions.setActiveToken("")
            _this.props.actions.setPassiveToken(null)
          }
        }
      })
    }
  }

  onDepositsignKeyChanged (e) {
    this.props.actions.setDepositSignSelectedKey(this.props.pkLocalState.depositsignKeysInfo.KeysInfo[e.target.value])
  }

  onVtcoKeyChanged (index) {
    this.setState({"pinEnabled": true})
    this.props.actions.setVtcoSelectedKey(this.props.pkLocalState.vtcoKeysInfo[index])
    this.setState({selectedToken: index})
    this.setState({showTokenList: false})
  }

  onProviderChanged (e) {
    if (this.props.base.authProvidersList[e.target.value].type !== undefined && this.props.base.authProvidersList[e.target.value].type === "kerberos") {
      this.props.actions.setIsKerberos(true)
    } else {
      this.props.actions.setIsKerberos(false)
    }
    this.props.actions.setVtcoKeysInfo(null)
    this.props.actions.setSelectedAuthProvider(this.props.base.authProvidersList[e.target.value].url)
    console.log(this.props.base.authProvidersList[e.target.value]);
  }

  handleVtcoPinChange (e) {
    this.props.actions.setVtcoPin(e.target.value)
  }

  onDropdownActiveTokenPathSelected(e) {
    this.props.actions.setActiveToken(e.target.value)
    this.props.actions.clearKeyContainer()
    this.props.actions.setPassiveToken("")
  }

  onDropdownPassiveTokenPathSelected(e) {
    this.props.actions.setPassiveToken(e.target.value)
    this.props.actions.clearKeyContainer()
    this.props.actions.setActiveToken("")
  }

  goToSource (url) {
    window.open(url, '_blank').focus();
  }


  render() {
    const isEnabled = (this.props.pkLocalState.keyContainerName && this.props.pkLocalState.keyContainerPassword.length > 0) || (this.props.pkLocalState.tokenPathHSM && this.props.pkLocalState.keyContainerPassword.length > 0) || (this.props.pkLocalState.depositsignSelectedKey !== null && this.props.pkLocalState.keyContainerPassword.length > 0) || (this.props.pkLocalState.vtcoSelectedKey !== null && this.props.pkLocalState.vtcoSelectedKey !== undefined && this.props.pkLocalState.keyContainerPassword.length > 0 && this.props.pkLocalState.vtcoPin) || (this.props.pkLocalState.vtcoSelectedKey !== null && this.props.pkLocalState.vtcoSelectedKey !== undefined && this.props.pkLocalState.vtcoPin.length > 0 && this.props.base.isKerberos)
      ? true
      : (this.props.pkLocalState.passiveTokenPath || this.props.pkLocalState.activeTokenPath) && this.props.pkLocalState.keyContainerPassword.length > 0
      ? true : false;

    const isEnabledGetTokens = (this.props.pkLocalState.depositsignUserName.length > 0 && this.props.pkLocalState.keyContainerPassword.length > 0) ? true : false

    const isEnabledGetVtcoTokens = ((this.props.pkLocalState.vtcoUserName.length > 0 && this.props.pkLocalState.keyContainerPassword.length > 0) || this.props.base.isKerberos) ? true : false

    const mode = (this.props.connectionStatus.cloudConnection || this.props.connectionStatus.uccConnection) ? parseInt(this.props.pkLocalState.selectedMode, 10) : 0;

    var changeTimeEnabled = mode < 6 ? true : false

    let showTokenListOpen = 'dropdown-menu'
    if (this.state.showTokenList) {
      showTokenListOpen = 'dropdown-menu show'
    }

    return (
      <div>
      {!this.props.pkLocalState.isActive ? 
        <div className="row" style={{paddingTop: "10px"}}>
          {
            this.props.mobileModeReducer.isMobileDevice
            ? null
            :  <div className="col-md-4 col-lg-4 d-none d-sm-none d-md-block">
                <div className="card">
                  <div className="card-header">
                      {i18next.t("pk:sessionParamsTitle")}
                  </div>
                  <div className="card-body">
                      <div>
                        <label>{i18next.t("pk:keyActivationPeriod")}</label>
                          <div className="input-group mb-4">
                            <input type="text" value={this.props.pkLocalState.settedTime} disabled={!changeTimeEnabled} onChange={this.onChangeDigits.bind(this)} className="form-control" />
                          </div>
                      </div>
                  </div>
                </div>
              </div>
          }
            {
                (!this.props.pkLocalState.cleanUpAllForms) ? this.cleanUpForm() : null
            }
            <div className={this.props.mobileModeReducer.isMobileDevice ? "col-md-12 col-lg-12 col-sm-12" : "col-md-8 col-lg-8 col-sm-12"}>
                <div className="card">
                    <div className="card-header">
                      {i18next.t("pk:keyParamsTitle")}
                    </div>
                    {
                        mode === 3
                        ? <MobileDSContainer />
                        : <div className="card-body">
                          {
                            mode === 4 || mode === 5 || mode >= 6
                            ? null
                            : <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-sm-12 col-form-label">{i18next.t("pk:CALabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-sm-12">
                                    {
                                      this.props.pkLocalState.listCSK ? <select type="select" id="ca-input" className="form-control" defaultValue={this.props.base.defaultACSKId} style={{textAlign: "center", textAlignLast: "center"}} onClick={this.onDropdownSelected} onChange={this.onDropdownSelected}>
                                              {
                                                this.props.pkLocalState.listCSK.map((child, index) =>
                                                  <option key={index} value={child.id} selected={this.state.selectedKeyContainer === child.id}>{child.name}</option>)
                                              }          
                                          </select> : null
                                    }
                                      
                                  </div>
                              </div>
                          }
                        <div>
                        <div className="form-group row">
                            <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:keyTypeLabel")}</label>
                              <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                  <select type="select" id="kt-input" className="form-control" style={{textAlign: "center", textAlignLast: "center"}} defaultValue={mode} onChange={this.onDropdownModeSelected}>
                                          <option key={0} value={0} selected={mode === 0}>{i18next.t("pk:fileOnDiskTitle")}</option>
                                          {
                                            !this.props.mobileModeReducer.isMobileDevice 
                                            ? <option key={1} value={1} selected={mode === 1}>{i18next.t("pk:pkcsDevicesActiveTitle")}</option>
                                            : null
                                          }
                                          {
                                            !this.props.mobileModeReducer.isMobileDevice 
                                            ? <option key={2} value={2} selected={mode === 2}>{i18next.t("pk:pkcsDevicesPassiveTitle")}</option>
                                            : null
                                          }
                                          {
                                            this.props.connectionStatus.cloudConnection && this.props.base.mobileIdEnabled === "true"
                                            ? <option key={3} value={3} selected={mode === 3}>{i18next.t("mobileDSTitle")}</option>
                                            : null
                                          }
                                          {
                                            this.props.connectionStatus.cloudConnection && this.props.base.cipherHSMEnabled === "true"
                                            ? <option key={4} value={4} selected={mode === 4}>{i18next.t("cipherHSMTitle")}</option>
                                            : null
                                          }
                                          {
                                            this.props.connectionStatus.cloudConnection && this.props.base.depositSignEnabled === "true"
                                            ? <option key={5} value={5} selected={mode === 5}>{i18next.t("pk:depositsignLabel")}</option>
                                            : null
                                          }
                                          {
                                            this.props.connectionStatus.cloudConnection && this.props.base.vtcoEnabled === "true" && this.props.base.vtcoList !== null
                                            ? this.props.base.vtcoList.map((child, index) =>
                                                  <option key={index + 6} value={index + 6} selected={mode === index + 6}>{child.vtcoTitle}</option>)
                                              
                                            : null
                                          }
                                  </select>
                              </div>
                          </div>
                          
                          {
                            mode === 0
                            ? <div className="form-group row">
                              <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:keyContainerPathLabel")}</label>
                                <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                {
                                  this.props.mobileModeReducer.mobileDeviceMode
                                  ? 
                                  <div className="input-group row" style={{marginLeft: "0px"}}>
                                    <input type="text" className="form-control col-lg-8 col-md-8 col-xs-12 col-sm-12" value={this.props.pkLocalState.keyContainerName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handleKCFile} readOnly />
                                    <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handleKCFile}>{i18next.t("chooseFile")}</button>
                                  </div>
                                  : 
                                  <div className="input-group">
                                    <input type="text" className="form-control" value={this.props.pkLocalState.keyContainerName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handleKCFile} readOnly />
                                        <div className="input-group-append">
                                          <button className="input-group-text" style={{"cursor": "pointer"}} onClick={this.handleKCFile}>{i18next.t("chooseFile")}</button>
                                        </div>
                                  </div>
                                }
                                <input id="keyContainerUpload" type="file" onChange={this.onChangeKCFile} style={{"display": "none"}} accept=".dat, .pfx, .jks, .p12, .zs2" />
                                </div>
                            </div> : null
                          }

                          {
                            mode === 1
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:keyContainerPathLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                  <div className="input-group">
                                    <input type="text" className="form-control" value={this.props.pkLocalState.activeToken} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.togglePopup.bind(this)} readOnly />
                                        <div className="input-group-append">
                                          <button className="input-group-text" style={{"cursor": "pointer"}} onClick={this.togglePopup.bind(this)}>{i18next.t("pk:chooseToken")}</button>
                                        </div>
                                  </div>
                                </div>
                              </div> : null
                          }
                          
                          {
                            mode === 2
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:keyContainerPathLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                  <div className="input-group">
                                    <input type="text" className="form-control" value={this.props.pkLocalState.passiveToken} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.togglePopup.bind(this)} readOnly />
                                        <div className="input-group-append">
                                          <button className="input-group-text" style={{"cursor": "pointer"}} onClick={this.togglePopup.bind(this)}>{i18next.t("pk:chooseToken")}</button>
                                        </div>
                                  </div>
                                </div>
                              </div> : null
                          }

                          {
                            mode === 4
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:tokenPathLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                  <div className="input-group">
                                    <input type="text" className="form-control" value={this.props.pkLocalState.tokenPathHSM} onChange={this.handleTokenPathChange} style={{"backgroundColor": "white"}} />
                                  </div>
                                </div>
                              </div> : null
                          }

                          {
                            mode === 5
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:loginLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                  <div className="input-group">
                                    <input type="text" className="form-control" value={this.props.pkLocalState.depositsignUserName} onChange={this.handleTokenUserNameChange} style={{"backgroundColor": "white"}} />
                                  </div>
                                </div>
                              </div> : null
                          }

                          {
                            this.props.base.authProvidersList !== null
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-sm-12 col-form-label">{i18next.t("authProvider")}</label>
                                  <div className="col-lg-8 col-md-12 col-sm-12">
                                    {
                                      this.props.base.authProvidersList !== null ? <select type="select" className="form-control" style={{textAlign: "center", textAlignLast: "center"}} disabled={this.props.base.authProvidersList.length < 2 ? true : false} onChange={this.onProviderChanged}>
                                              {
                                                this.props.base.authProvidersList.map((child, index) =>
                                                  <option key={index} value={index}>{child.clientTitle}</option>)
                                              }          
                                          </select> : null
                                    }
                                      
                                  </div>
                              </div>
                            : null
                          }

                          {
                            mode >= 6 && !this.props.base.isKerberos
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:loginLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                  <div className="input-group">
                                    <input type="text" className="form-control" value={this.props.pkLocalState.vtcoUserName} onChange={this.handleVtcoUserNameChange} style={{"backgroundColor": "white"}} />
                                  </div>
                                </div>
                              </div> : null
                          }

                          {
                            !this.props.base.isKerberos
                            ? <div className="form-group row">
                              <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:PasswordLabel")}</label>
                                <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                <div className="input-group">
                                    <input className="form-control" value={this.props.pkLocalState.keyContainerPassword} type={this.state.showPassword ? "input" : "password"} onChange={this.handlePasswordChange} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
                                        <div className="input-group-append">
                                            <div className="input-group-text" onClick={this.setShowPassword.bind(this)} onMouseOver={this.hover.bind(this)} onMouseOut={this.out.bind(this)}><i className="fa fa-eye"></i></div>
                                        </div>
                                  </div>
                                </div>
                            </div>
                            : null
                          }

                          {
                            mode === 5
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:oneTimePassLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                  <div className="input-group">
                                    <input type="text" className="form-control" value={this.props.pkLocalState.twoFactorCode} onChange={this.handleTwoFactorCodeChange} style={{"backgroundColor": "white"}} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
                                  </div>
                                </div>
                              </div> : null
                          }

                          {
                            this.props.pkLocalState.depositsignKeysInfo
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-sm-12 col-form-label">{i18next.t("pk:tokenLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-sm-12">
                                    {
                                      this.props.pkLocalState.depositsignKeysInfo.KeysInfo ? <select type="select" className="form-control" onChange={this.onDepositsignKeyChanged} style={{textAlign: "center", textAlignLast: "center"}}>
                                              {
                                                this.props.pkLocalState.depositsignKeysInfo.KeysInfo.map((child, index) =>
                                                  <option key={index} value={index} selected={child.KeyName === this.props.pkLocalState.depositsignSelectedKey.KeyName ? true : false}>{child.CertificatesInformation[0].FullName}</option>)
                                              }          
                                          </select> : null
                                    }
                                      
                                  </div>
                              </div>
                            : null
                          }

                          {
                            this.props.pkLocalState.vtcoKeysInfo
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-sm-12 col-form-label">{i18next.t("pk:tokenLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-sm-12">
                                    {/*{
                                      this.props.pkLocalState.vtcoKeysInfo !== null ? <select type="select" className="form-control cut-text" onChange={this.onVtcoKeyChanged}>
                                              {
                                                this.props.pkLocalState.vtcoKeysInfo.map((child, index) =>
                                                  <option key={index} value={index} selected={child.uri === this.props.pkLocalState.vtcoSelectedKey.uri ? true : false}>{child.title}&nbsp;{child.agreementKey !== null ? "[" + child.agreementKey.subjectCommonName + "]" : "[" + child.signatureKey.subjectCommonName + "]"}</option>)
                                              }          
                                          </select> : null
                                    }*/}

                                    {
                                      this.props.pkLocalState.vtcoKeysInfo !== null && this.props.pkLocalState.vtcoKeysInfo !== undefined
                                      ? <div style={{paddingLeft: "0px", paddingRight: "0px"}}>
                                          <div className="dropdown col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                                              <button className="btn token-dropdown col-lg-12 col-md-12 col-xs-12 col-sm-12" type="button" id="dropdownMenuButton1" disabled={this.props.pkLocalState.vtcoKeysInfo.length === 0 ? true : false} data-bs-toggle="dropdown" aria-expanded="false" onClick={this.setShowTokenList}>
                                                <label className="text-overflow-label" style={{marginBottom: "0px"}}>{this.props.pkLocalState.vtcoKeysInfo[this.state.selectedToken] !== undefined ? (this.props.pkLocalState.vtcoKeysInfo[this.state.selectedToken].title + " " + (this.props.pkLocalState.vtcoKeysInfo[this.state.selectedToken].agreementKey !== null ? "[" + this.props.pkLocalState.vtcoKeysInfo[this.state.selectedToken].agreementKey.subjectCommonName + "]" : "[" + this.props.pkLocalState.vtcoKeysInfo[this.state.selectedToken].signatureKey.subjectCommonName + "]")) : i18next.t("noKeysWarningToken")}</label>
                                              </button>
                                              <div className={showTokenListOpen} style={{width: "inherit"}}>
                                                {
                                                    this.props.pkLocalState.vtcoKeysInfo.map((provider, index) =>
                                                    <div className="dropdown-item cursor-pointer" key={index} onClick={this.onVtcoKeyChanged.bind(this, index)}>
                                                      <p style={{marginBottom: "0px"}}>
                                                        {provider.uri}
                                                      </p>
                                                      <p style={{marginBottom: "0px", fontSize: "14px"}}>
                                                        {provider.signatureKey !== null ? provider.signatureKey.subjectCommonName : provider.agreementKey.subjectCommonName}
                                                      </p>
                                                      <p style={{marginBottom: "0px", fontSize: "14px"}}>
                                                        {provider.signatureKey !== null ? provider.signatureKey.issuerCommonName : provider.agreementKey.issuerCommonName}
                                                      </p>
                                                      <p style={{marginBottom: "8px", fontSize: "14px"}}>
                                                        {
                                                          provider.signatureKey !== null
                                                          ? (provider.signatureKey.algorithmInfo === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (provider.signatureKey.algorithmInfo === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : provider.signatureKey.algorithmInfo))
                                                          : provider.agreementKey.algorithmInfo === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (provider.agreementKey.algorithmInfo === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : provider.agreementKey.algorithmInfo)
                                                        }
                                                      </p>
                                                    </div>)
                                                }
                                            </div>
                                          </div>
                                        </div>
                                      : null
                                    }
                                      
                                  </div>
                              </div>
                            : null
                          }
                          {
                            this.props.pkLocalState.vtcoKeysInfo && this.state.pinEnabled
                            ? <div className="form-group row">
                                <label className="col-lg-4 col-md-12 col-sm-12 col-form-label">{i18next.t("pk:pinLabel")}</label>
                                  <div className="col-lg-8 col-md-12 col-sm-12">
                                    <div className="input-group">
                                      <input type={this.state.showVtcoPin ? "input" : "password"} className="form-control" value={this.props.pkLocalState.vtcoPin} onChange={this.handleVtcoPinChange} style={{"backgroundColor": "white"}} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
                                        <div className="input-group-append">
                                            <div className="input-group-text" onClick={this.setShowVtcoPin.bind(this)} onMouseOver={this.hoverPin.bind(this)} onMouseOut={this.outPin.bind(this)}><i className="fa fa-eye"></i></div>
                                        </div>
                                    </div> 
                                  </div>
                              </div>
                            : null
                          }

                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-md-4">
                              {
                                mode === 5
                                ? <div>
                                    {
                                      !this.props.pkLocalState.depositsignKeysInfo
                                      ? <button id="submit" className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={!isEnabledGetTokens} style={{marginBottom: "10px"}} onClick={this.getKeys.bind(this)}>{i18next.t("pk:getTokensLabel")}
                                          {
                                            this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                              : null
                                          }
                                        </button>
                                      : <button id="submit" className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} disabled={!isEnabled} onClick={this.confirm.bind(this)}>{i18next.t("pk:startWorkWithTokenLabel")}
                                          {
                                            this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                              : null
                                          }
                                      </button>
                                    }
                                      
                                </div>
                                : mode >= 6
                                  ? <div>
                                      {
                                        !this.props.pkLocalState.vtcoKeysInfo
                                        ? <button id="submit" className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={!isEnabledGetVtcoTokens} style={{marginBottom: "10px"}} onClick={this.getVtcoKeys.bind(this)}>{i18next.t("pk:getTokensLabel")}
                                            {
                                              this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                                : null
                                            }
                                          </button>
                                        : <button id="submit" className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} disabled={!isEnabled} onClick={this.confirm.bind(this)}>{i18next.t("pk:startWorkWithTokenLabel")}
                                            {
                                              this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                                : null
                                            }
                                        </button>
                                      }
                                        
                                  </div>
                                  : <button id="submit" className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} disabled={!isEnabled} onClick={this.confirm.bind(this)}>{i18next.t("pk:startWorkLabel")}
                                      {
                                        this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                          : null
                                      }
                                  </button>
                              }
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 order-2 order-md-6 no-left-padding">
                                <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
                            </div>
                        </div>
                      </div>
                    }
                    {
                        this.props.base.uosAgentNativeBuilds !== undefined && this.props.base.uosAgentNativeBuilds.length > 0 && !this.props.mobileModeReducer.isMobileDevice 
                        ? <div className="card-footer view-after">
                            {
                              this.props.base.uosAgentNativeBuilds.map((source) => {
                                var name = "", _this = this, counter = 0;
                                _this.props.base.uosAgentNativeBuilds.forEach((data) => {
                                  if ((data.name.indexOf("windows32") !== -1) || (data.name.indexOf("windows64") !== -1)) {
                                    counter += 1;
                                  }
                                })
                                name = source.name
                                if (source.name.indexOf("windows32") !== -1) {
                                  name = "windows"
                                }

                                if (source.name.indexOf("windows64") !== -1) {
                                  name = "windows64"
                                }

                                if (source.name.indexOf("linux") !== -1) {
                                  name = "linux"
                                }

                                if (source.name.indexOf("macos") !== -1) {
                                  name = "apple"
                                }

                                return <button className="btn right-download-button" style={{fontSize: "10px", color: "#777"}} onClick={this.goToSource.bind(this, source.url)}><i className={"fa " + "fa-" + (name === "windows64" ? "windows" : name)} style={{fontSize: "1.2rem", color: "#888"}}></i>{name === "windows64" ? "x64" : ((counter > 1 && name === "windows64") || (counter > 1 && name === "windows")) ? "x32" : ""}</button>
                              })
                            }
                            
                          </div>
                        : null
                      }
                  </div>
                  
              </div>
              
        </div> : null }

        {this.props.pkLocalState.isActive ? <div className="row" style={{paddingTop: "10px", paddingBottom: "10px"}}>
            <div className="col-md-12 col-lg-4 col-sm-12 col-xs-12" style={{paddingBottom: "10px"}}>
              <div className="card">
                <div className="card-header" onClick={this.expandActions.bind(this)} role="button">
                    {i18next.t("pk:actionsTitle")}
                </div>
                <div className={this.state.collapseActionsShow}>

                        <button className={this.props.pkLocalState.selectedTab === "CertificateInfo" ? 'btn btn-outline-info btn-block' : 'btn btn-default btn-block'} style={{marginBottom: "10px"}} onClick={this.setSelectedCertificateInfoTab.bind(this)}>{i18next.t("pk:keyInfoButtonTitle")}</button>
                        {
                          this.props.certificateInfo
                          ? <button className={this.props.pkLocalState.selectedTab === "CertificateInfoSignature" ? 'btn btn-outline-info btn-block' : 'btn btn-default btn-block'} style={{marginBottom: "10px"}} onClick={this.setSelectedSignatureTab.bind(this)}>{i18next.t("signatureCertificate")}</button>
                          : null
                        }
                        {
                          this.props.certificateInfoKeyAgreement
                          ? <button className={this.props.pkLocalState.selectedTab === "CertificateInfoKeyAgreement" ? 'btn btn-outline-info btn-block' : 'btn btn-default btn-block'} style={{marginBottom: "10px"}} onClick={this.setSelectedKeyAgreementTab.bind(this)}>{i18next.t("encryptionCertificate")}</button>
                          : null
                        }
                        
                        <button className="btn btn-default btn-block" style={{marginBottom: "10px"}} onClick={this.endWork.bind(this)}>{i18next.t("pk:endWorkwithKeyTitle")}</button>
                </div>
              </div>
            </div>
              {
                this.props.pkLocalState.selectedTab === "CertificateInfo" ? <CertificateInfo /> : this.props.pkLocalState.selectedTab === "CertificateInfoSignature" ? <CertificateInfoSignature /> : <CertificateInfoKeyAgreement />
              }
            </div> : null
      }

      {
        this.state.showPopup ? 
          <Popup closePopup={this.togglePopup.bind(this)} />
        : null
      }

      {
        this.state.showJavaDownloadPopup ? 
          <JavaDownloadPopup closeJavaDownloadPopup={this.toggleJavaDownloadPopup.bind(this)} />
        : null
      }
        
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        locales: state.i18n,
        localesReducer: state.localesReducer,
        dafaultState: state.dafaultState,
        keyContainers: state.keyContainers,
        selectedKey: state.selectedContainer,
        clientKCState: state.clientKCState,
        pkLocalState: state.privateKeyReducer,
        certificateInfo: state.certificateInfo.certInfoSignature,
        connectionStatus: state.connectionStatusReucer,
        certificateInfoKeyAgreement: state.certificateInfo.certInfoKeyAgreement,
        base: state.base,
        mobileModeReducer: state.mobileModeReducer,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      getSupportedCA,
      createSession,
      sendSessionOption,
      sendKeyContainer,
      getCertificateInfo,
      deleteSession,
      setCAId,
      setCertificateInfo,
      clearSelectedKeyContainer,
      setSelectedKeyContainer,
      setKeyContainer,
      clearKeyContainer,
      setKeyContainerPass,
      clearKeyContainerPass,
      clearCertificateInfo,
      getCertificateInfoKeyAgreement,
      setCertificateInfoKeyAgreement,
      setSelectedTab,
      clearCertificateInfoKeyAgreement,
      getCertificateInBase64,
      setActivationPeriod,
      startTic,
      setIsActive,
      setCertificateInfoKeyAgreementBase64,
      setSelectedMode,
      setActiveToken,
      setPassiveToken,
      sendTokenPath,
      setPassiveTokenPath,
      setActiveTokenPath,
      getConnecteds,
      setCleanUpAll,
      clearInputFileForEncrypt, clearCertificateInputFile, clearInputFileForDecrypt,
      clearDecryptedData, clearEncryptedData, clearInputFileForDS, getKCInfo,
      clearDSData, clearInputDescription,
      openFileDialog, clearPrevisionDsFile, setKeyContainerPath, clearInputTextForDS,
      clearDSInputTextDescription, clearDSDataBase64, clearFileList, clearFileListWithDS,
      clearPrevisionDsText,
      clearDecryptText, clearInputTextForDecryptUintArr, clearInputTextForDecrypt,
      clearInputTextForEncryptUintArr,
      clearInputTextForEncrypt, clearEncryptDataBase64,
      clearFilesForEncrypt, clearEncryptedFiles, setDSActive,
      setActionsExpand, setMobileDSMode,
      clearFullPhoneNumber, clearPhoneNumber, clearOwnerOrganization, setShowUpgradeCard,
      clearBaseFileForUpgrade, clearFileWithDSForUpgrade, clearModifiedDSData,
      clearUpgradedDSInBase64, clearTextDSDataBase64, clearBaseTextForUpgrade,
      clearInputTextDSForVerify, clearVerifyInputText, clearVerifyResultText, clearTextSignedData,
      clearVerifyResultData, clearFileSignedData, clearVerifyInputFile, clearVerifyDSInputFile,
      clearQRList, clearVerifyResultsForAddToExist, clearQRVerifyResultsForAddToExist,
      setBodyIsMounted, setTokenPathHSM, clearTokenPathHSM, setDepositSignUserName, clearDepositSignUserName,
      depositsignLogin, setDepositSignKeysInfo, clearDepositSignKeysInfo,
      setDepositSignSelectedKey, clearDepositSignSelectedKey, depositsignGetCertInfo,
      setUseUccConnection, setUseTwoFactorCode, setTwoFactorCode, setShowPinInput,
      clearVPNToken, clearVPNTokenData, clearProfileFields, clearResultKey, clearSecret,
      clearErrorRespons, setAllFieldsFiled, setVtcoUserName, clearVtcoUserName,
      vtcoLogin, setVtcoKeysInfo, clearVtcoKeysInfo, setVtcoSelectedKey, clearVtcoSelectedKey,
      setTwoFactorEnable, setVtcoTokenBody, getVtcoTokenList, getVtcoProtectedParams,
      setProtectedParams, clearProtectedParams, getVtcoTokenEnquire, setIsVtco, setVtcoPin,
      setVtcoUrl, setKeyAgreementCertificate, setSignatureCertificate, setDuplicateSign,
      setAddDsToExist, setTokenInfo, setShowPreviewPopup, setShowPreviewPopupVerify,
      setShowErrorPopup, setErrorData, setIsKerberos, setSelectedAuthProvider,
      setAuthProvidersList, setXadesType, setAsicContainerForm, setExpireTime,
      showEncryptPreviewPopup, showDecryptPreviewPopup, setDecryptionError
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateKeyContainer);